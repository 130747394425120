var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review", attrs: { id: "loadbgcontent" } },
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    width: "240px",
                    "justify-content": "center",
                    display: "flex"
                  }
                },
                [_c("div", { attrs: { id: "loadbg" } })]
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { width: "260px" } },
                [
                  _c("el-progress", {
                    attrs: {
                      "stroke-width": 26,
                      percentage: _vm.percentage,
                      color: _vm.colors
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v(_vm._s(_vm.$t("The file is generating")))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }