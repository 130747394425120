<template>
        <div id="loadbgcontent" class="review">
            <el-form>
                <el-row>
                    <el-col style="width: 240px; justify-content: center; display: flex;">
                        <div id="loadbg"></div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col style="width:260px">
                        <el-progress :stroke-width="26" :percentage="percentage" :color="colors"></el-progress>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <span style="font-size:18px;">{{ $t('The file is generating') }}</span>
                    </el-col>
                </el-row>
                <!--<el-row>
                <el-col>
                <el-button type="primary" @click="start">开始</el-button> <el-button type="danger" @click="over">结束</el-button>
                </el-col>
            </el-row>-->
            </el-form>
        </div>
</template>


<style>
    .messgeStyle {
        overflow:scroll;
        overflow-x:hidden;
        width:100%;
        height:500px;
    }
#loadbg {
  width: 90px;
  height: 90px;
  background: url("/img/loadBG03.gif") center center no-repeat;
  background-size: cover;
  position: relative;
}

#loadbgcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>

<script>
import {packageProgress, addPackage, IsHavePackage, add,addSummaryPackage} from "@/api/orderPackage";

export default {
  data() {
     return {
      Speed:"",
      OrderId: "",
      packageId: "",
      timer: "",
      status: true,
      value: 0,
      percentage: 0,
      RedisPercentage: 0,
      maxTimes:0,
      url: "",
      colors: [
        {color: '#000066', percentage: 20},
        {color: '#000066', percentage: 40},
        {color: '#000066', percentage: 60},
        {color: '#000066', percentage: 80},
        {color: '#000066', percentage: 100}
      ]
    }
  },
  created() {
    this.init()
  },
  watch: {
    $route(to, from) {
      this.init()
    },
    percentage(New) {
      if (New == 90) {
        // clearInterval(this.timer)
        // this.percentage = New == 90 ? 90 : New

        this.status = false;

      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      //新增文件包
      this.OrderId = this.$route.query.OrderId;
      this.Speed = this.$route.query.Speed;
      this.add();
      this.addSummary();
    },
    addSummary(){
           let paramrow = {
                      id: "",
                      orderId: this.OrderId,
                      path: "Pending",
                      size: 0,
                      remark: this.Speed,
                      status: 0,
                      createUserid: '',
                      corporationId: '',
                      pDFType: "5",
                  };
                  addSummaryPackage(paramrow).then(res => {
                      this.loadSummaryId = res.data.result
                  }).catch((erro) => {
                      console.log(erro)
                  });
    },
    add() {
      let paramrow = {
        id: "",
        orderId: this.OrderId,
        path: "Pending",
        size: 0,
        remark: this.Speed,
        status: 0,
        createUserid: '',
        corporationId: '',
        pDFType: "1",
      };
      addPackage(paramrow).then(res => {
        this.packageId = res.data.result

      }).catch((erro) => {
        console.log(erro)
      });
      //读取进度
      this.startProgress();
    },
    startProgress() {
      this.timer = setInterval(this.valChangeProgress, 4000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
      valChangeProgress() {
          this.maxTimes++;
          if (this.maxTimes >= 280) {
              this.doOver();
          }
          if (this.packageId != "") {
              if (this.percentage == 100) {
                  //获取是否有保存路径
                  IsHavePackage(this.packageId).then(res => {
                      var s_url = res.data.result;
                      if (s_url != "") {
                          this.over();
                          //新增操作记录
                          this.addLog(1);
                          //关闭当前子页
                          this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.OrderId } })
                      }
                      //读取进度
                  }).catch((erro) => {
                      console.log(erro)
                  });
              }
              else {
                  packageProgress(this.packageId).then(res => {
                      var progress = res.data;
                      console.log("progress", progress);
                      if (progress != null) {
                          if (progress.Type == -1) {
                              this.doOver();
                          }
                          if (progress.Type == 1) {
                              this.RedisPercentage = progress.Progress;
                              if (this.RedisPercentage != 0 && this.RedisPercentage >= this.percentage) {
                                  this.percentage = this.RedisPercentage;
                              } else {
                                  if (this.status) {
                                      this.percentage++;
                                  }
                              }
                          }
                          //弹出框 错误信息
                          if (progress.Type == 0) {
                              this.RedisPercentage = progress.Progress;
                              if (this.RedisPercentage != 0 && this.RedisPercentage >= this.percentage) {
                                  this.percentage = this.RedisPercentage;
                              } else {
                                  if (this.status) {
                                      this.percentage++;
                                  }
                              }
                              //添加最后一次请求及进度100才提示异常判断
                              if (progress.warn != null && (this.maxTimes == 279 || this.percentage==100))
                              {
                                  const newDatas = [];
                                  const h = this.$createElement;
                                  newDatas.push(h("p", null, this.$t('The generated PDF attachment is abnormal, please check it')+". "+this.$t('total')+":"+(progress.warn.length+1)));
                                  progress.warn.forEach(v => {
                                      var warntype = "";
                                      if (v.WarnType == 1) {
                                          warntype = this.$t('PDF attachments are encrypted');
                                      }
                                      else if (v.WarnType == 2) {
                                          warntype = this.$t('The file is damaged');
                                      }
                                      else {
                                          warntype = this.$t('OTHER');
                                      }
                                      // + " " + this.$t('PO') + " : " + v.PO) + " " + this.$t('SUPPLIER') + " : " + v.CompanyName)
                                      newDatas.push(h("p", null, warntype + "-" + this.$t('Order Number') + " : " + v.PO+ " " + this.$t('SUPPLIER') + " : " + v.CompanyName+" " + this.$t('PDF POSITION WITHIN') + " : " + v.FileName))
                                  })
                                  this.$confirm(this.$t('Tips'), {
                                      title: this.$t('Tips'),
                                      message: h("div", null, newDatas),
                                      showCancelButton: true,
                                      confirmButtonText: this.$t('Yes'),
                                      cancelButtonText: this.$t('Cancel'),
                                      type: "error",
                                      customClass: 'messgeStyle',
                                  }).then(async (action) => { });
                              }
                          }
                      }
                      else {
                          if (this.status) {
                              this.percentage++;
                          }
                      }
                      //读取进度
                  }).catch((erro) => {
                      console.log(erro)
                  });
              }
          } else {
              if (this.status) {
                  this.percentage++;
              }
          }
      },
      doOver() {
          this.over();
          this.$confirm(this.$t('File generation failed, please contact the background administrator'), this.$t('Tips'), {
              confirmButtonText: this.$t('Yes'),
              cancelButtonText: this.$t('Cancel'),
              type: "warning"
          }).then(() => {

          })
          return false;
      },
    start() {
      this.timer = setInterval(this.valChange, 50000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
    valChange() {
      if (this.percentage != 100) {
        this.percentage += 10;
        console.log(this.percentage);
      } else {
        this.percentage -= 10;
        console.log(this.percentage);
      }

    },
    over() {
      clearInterval(this.timer);
    },
    increase() {
      this.percentage += 10;
      if (this.percentage > 100) {
        this.percentage = 100;
      }
    },
    decrease() {
      this.percentage -= 10;
      if (this.percentage < 0) {
        this.percentage = 0;
      }
    },
    addLog(type) {
      let paramrow = {
        orderPackageId: this.packageId,
        type: type,
        remark: "",
        status: 0,
        createUserid: '',
        corporationId: ''
      };
      add(paramrow).then(() => {
      }).catch((erro) => {
        console.log(erro)
      });
    },
  }
}
</script>
